import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import Image from "../components/image"
import styled, { keyframes } from "styled-components"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

const bounce = keyframes`
from {
  transform: translateY(0);
}
to {
  transform: translateY(20px);
}`

const HeaderCenter = styled.div`
  position: absolute;
  bottom: 2px;
  font-family: Candl;

  max-width: 300px;
  margin: auto;
  color: white;
`

const ChevronDown = styled(FontAwesomeIcon)`
  display: block;
  font-size: 91px;
  animation: ${bounce} 0.5s ease-in-out infinite;
  animation-direction: alternate;
`
function scrollIntoView() {}

const Header = ({ siteTitle, scrollIntoView }) => {
  return (
    <header
      style={{
        background: `black`,
        marginBottom: `1.45rem`,
        fontFamily: "Candl",
      }}
    >
      <div
        style={{
          margin: `0 auto`,
          maxWidth: 960,
          height: "100vh",
          padding: `1.45rem 1.0875rem`,
        }}
      >
        <h1 style={{ margin: 0 }}>
          <Link
            to="/"
            style={{
              color: `white`,
              textDecoration: `none`,
              fontFamily: "Candl Bold",
              fontSize: "91px",
            }}
          >
            {siteTitle}
          </Link>
        </h1>
        <HeaderCenter>
          Hot takes and fresh finds for the refined bad bitch.
          <a onClick={() => scrollIntoView()}>
            <ChevronDown icon="chevron-down" />
          </a>
        </HeaderCenter>
      </div>
    </header>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
